import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import LeagueSummary from './../league/LeagueSummary'
import NewDivisionDialog from './../league/NewDivisionDialog'
import DivisionTable from './../league/DivisionTable'
import Link from '@material-ui/core/Link'
import { leagueSlice } from 'league/redux'
import moment from 'moment'

class Teams extends React.Component {
  render() {
    const { openAddDivisionModal, openRolloverModal } = this.props
    return (
      <div>
        <NewDivisionDialog />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          <Grid item xs={8} style={{ textAlign: 'center', width: '100%' }}>
            <DivisionTable />
            <Button
              style={{ margin: 10, textAlign: 'right' }}
              onClick={openAddDivisionModal}
              className="save-btn"
            >
              Add age group
            </Button>
            {this.props.is_admin && this.props.league?.rollover_to > 0 && (
              <Link
                href={`/league/${this.props.league.rollover_to}`}
                >
                <Button
                  style={{ margin: 10, textAlign: 'right' }}
                  onClick={() => null}
                  className="save-btn"
                >
                  Go to Rollovered League
                </Button>
              </Link>)
            }
            {this.props.is_admin && (this.props.league?.rollover_to === 0) && moment().isAfter(moment.unix(Math.round((this.props.league.start_date + this.props.league.end_date)/2.0))) && (
              <Button
                style={{ margin: 10, textAlign: 'right' }}
                onClick={openRolloverModal}
                className="save-btn"
              >
                Rollover
              </Button>
              )
            }
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center', width: '100%' }}>
            <LeagueSummary hide_broadcast />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  league: state.league?.league,
  is_admin: state.user.user_type === 'admin',
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  openAddDivisionModal: () => dispatch(leagueSlice.actions.updateNewDialog({ key: 'open', value: true })),
  openRolloverModal: () => dispatch(leagueSlice.actions.updateRolloverDialog({ key: 'open', value: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Teams)
