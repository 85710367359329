import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  classes: [],
  nb_classes_total: 0,
  search_limit: 10,
  search_offset: 0,
  resetPagination: false,
  classInfo: null,
  filters: {
    query: '',
    facility_id: [],
    start_date: null,
    end_date: null,
  },
  form: {
    name: '',
    description: '',
    start_date: null,
    end_date: null,
    new_product_id: null,
    registration_start_date: null,
    registration_end_date: null,
    available_online: true,
    accepts_free_trial: false,
    max_kids: 8,
    fee: '',
    facility_id: null,
    is_camp: false,
    require_membership: false,
    class_type: null,
    properties: [],
  },
  attendee: null,
  attendee_form_open: null,
  fee_dialog_open: false,
  tab: 'events',
  alert_message_open: false,
  alert_message: null,
  broadcast_message_open: false,
  clone_open: false,
  new_event_modal_open: false,
  new_event_modal_edit: false,
  fees: [],
  class_event_modal: false,
  creation_modal_open: false,
  participant_modal: false,
  quota_edit_open: false,
  change_class: {
    open: false,
    new_class_id: null,
    attendee_id: null,
    facility_id: '',
    season: null,
    season_id: null,
  },
  change_trial: {
    open: false,
    new_event_id: null,
    attendee: null,
    current_class_id: null,
    current_division_id: null,
    current_class_data: null,
  },
  new_division: {
    name: null,
    open: false,
  },
}

const classesSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
      getClasses: (state, { payload }) => {
        const classes = payload.classes?.map(c => {
          return {
            ...c,
            attendees: c.is_container
              ? c.divisions?.map(d => d.attendees).flat()
              : c.attendees,
          }
        })
        state.classes = classes
        if (payload.nb_classes_total && payload.nb_classes_total > 0) {
          state.nb_classes_total = payload.nb_classes_total
        }
      },
      setSearchLimit: (state, { payload }) => {
        state.search_limit = payload.limit
      },
      setSearchOffset: (state, { payload }) => {
        state.search_offset = payload.offset
      },
      resetPagination: (state, { payload }) => {
        state.resetPagination = !state.resetPagination
      },
      getClass: (state, { payload }) => {
        let invoiced = 0,
          paid = 0
        payload.classInfo.divisions?.forEach(division => {
          division?.attendees?.forEach(attendee => {
            if (attendee.invoice && attendee.invoice.ID) {
              invoiced +=
                attendee.invoice.invoiced - attendee.invoice.discounted
              paid += attendee.invoice.paid
            }
          })
        })
        let attendees_count = payload?.classInfo?.attendees
        if (payload?.classInfo?.is_container) {
          attendees_count = payload?.classInfo.divisions
            ?.map(d => d.attendees)
            ?.flat().length
        }
        let class_type = 2
        if (payload?.classInfo?.newProduct.gl?.code.slice(0, 3) === '141') {
          class_type = 3
        } else {
          if (payload?.classInfo?.newProduct.gl?.code.slice(0, 3) === '140') {
            class_type = 1
          }
        }
        payload?.classInfo
        state.classInfo = {
          ...payload.classInfo,
          invoiced,
          paid,
          class_type,
          balance: invoiced - paid,
          attendees_count,
          attendees: payload?.classInfo?.attendees?.map(attendee => {
            let details = {}
            try {
              details = JSON.parse(attendee.customer.details)
            } catch (e) {
              console.error(e)
            }
            return {
              ...attendee,
              details,
            }
          }),
        }
        ;(state.tab = payload.classInfo?.is_container ? 'divisions' : 'events'),
          (state.form = {
            ...state.form,
            ...state.classInfo,
          })
      },
      getClassDataEditTrial: (state, { payload }) => {
        state.change_trial.current_class_data = payload.classInfo
      },
      changeFilterDetails: (state, { payload }) => {
        state.filters = {
          ...state.filters,
          [payload.key]: payload.value,
        }
      },
      toggleCreationModal: (state, { payload }) => {
        state.creation_modal_open = payload.open
      },
      updateForm: (state, { payload }) => {
        state.form = {
          ...state.form,
          [payload.key]: payload.value,
        }
      },
      updateAttendee: (state, { payload }) => {
        state.attendee = {
          ...state.attendee,
          [payload.key]: payload.value,
        }
      },
      openUpdateAttendeeSize: (state, { payload }) => {
        state.attendee = payload
        state.attendee_form_open = true
      },
      changeContractDetails: (state, { payload }) => {
        state.new_contract = {
          ...state.new_contract,
          [payload.field]: payload.value,
        }
      },
      toggleFeeModal: (state, { payload }) => {
        state.fee_dialog_open = payload.open
      },
      toggleUniformSizeModal: (state, { payload }) => {
        state.attendee_form_open = payload.open
      },
      toggleEventEditModal: (state, { payload }) => {
        state.new_event_modal_edit = true
        state.new_event_modal_open = payload.open
      },
      toggleEventCreationModal: (state, { payload }) => {
        state.new_event_modal_edit = false
        state.new_event_modal_open = payload.open
      },
      changeTab: (state, { payload }) => {
        state.tab = payload.tab
      },
      openAlert: (state, { payload }) => {
        state.alert_message_open = true
        state.alert_message = payload.message
      },
      closeAlert: (state, { payload }) => {
        state.alert_message_open = false
        state.alert_message = null
      },
      updateBroadcastMessage: (state, { payload }) => {
        state.broadcast_message_open = payload.open
      },
      updateClone: (state, { payload }) => {
        state.clone_open = payload.open
      },
      deleteEvent: (state, { payload }) => {
        state.classInfo = {
          ...state.classInfo,
          events: state.classInfo.events.filter(e => e.ID !== payload.id),
        }
      },
      getFees: (state, { payload }) => {
        state.fees = payload.fees
      },
      toggleClassEventModal: (state, { payload }) => {
        state.class_event_modal = payload
      },
      toggleAddParticipantModal: (state, { payload }) => {
        state.participant_modal = payload
      },
      toggleQuotaEdit: (state, { payload }) => {
        state.quota_edit_open = payload.open
        state.form = {
          ...state.form,
          quota: payload.quota || 0,
        }
      },
      editChangeClass: (state, { payload }) => {
        state.change_class = {
          ...state.change_class,
          [payload.key]: payload.value,
        }
      },
      editChangeTrial: (state, { payload }) => {
        state.change_trial = {
          ...state.change_trial,
          [payload.key]: payload.value,
        }
      },
      updateNewDialog: (state, { payload }) => {
        state.new_division = {
          ...state.new_division,
          [payload.key]: payload.value,
        }
      },
    },
  }),
  actions = classesSlice.actions

export { classesSlice, actions }
