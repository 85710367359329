import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { unixToDate, unixToTime, unixToDateTime } from './../helper'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  leagues: [],
  tournaments: [],
  filter: {
    query: '',
    active: 1,
    facility_id: [],
    product_type_id: null,
    start_date: null,
    end_date: null,
    hide_paid: false,
  },
  age_groups: [],
  query: '',
  dialog_open: false,
  dialog_edit: false,
  create_league: {
    ID: null,
    name: '',
    description: '',
    facility_id: null,
    product_id: null,
    age_group_id: null,
    start_date: null,
    end_date: null,
    gender: null,
    day_of_week: null,
    new_team: null,
    fee_amt: null,
    discount_amt: null,
    registration_start_date: null,
    registration_end_date: null,
    enable_deposit: true,
    enable_individual: true,
    enable_early_bird: true,
    display_schedule: true,
    available_online: true,
    max_teams: 8,
    create_team: false,
    redirect_to_league: true,
    tournament: false,
    duration: 45,
    color: '#3f51b5',
    properties: [],
  },
  league: null,
  league_teams: [],
  league_tab: 'teams',
  league_page: 0,
  add_team_dialog_open: false,
  scheduler: {
    field: null,
    weekday: null,
    start_time: null,
    end_time: null,
    games: 7,
    duration: 45,
  },
  scheduler_slots: [],
  scheduler_matches: [],
  league_matches: [],
  league_standings: [],
  player_matches: [],
  player_matches_dialog_open: false,
  playoff_matches: [],
  player_profile_dialog_open: false,
  eplayer_id: null,
  eplayer: {},
  new_game: {
    modal_open: false,
    home_team_id: null,
    away_team_id: null,
    game_type: 'regular',
  },
  new_playoffs: {
    duration: 45,
    round: 4,
    round_settings: [
      {
        level: 4,
        field_id: null,
        start_date: null,
        start_time: null,
        end_time: null,
      },
      {
        level: 2,
        field_id: null,
        start_date: null,
        start_time: null,
        end_time: null,
      },
    ],
  },
  built_playoffs: [],
  add_team_tab: 'existing',
  broadcast_message: {
    open: false,
    text: null,
    send_to_captains: false,
    send_to_all_team: false,
  },
  new_division: {
    name: null,
    open: false,
  },
  rollover: {
    open:false,
    league_parent_id: null,
    selected_leagues: [],
    current_tab: 'season',
    name: null,
    dates: {
      start_date: null,
      end_date: null,
      registration_start_date: null,
      registration_end_date: null,
      early_bird_end_date: null,
      early_bird_start_date: null,
    },
    settings: {
      facility_id: null,
      description: null,
      new_product_id: null,
      new_product_price: null,
      age_group_id: null,
      max_teams: null,
      duration: null,
      enable_deposit: false,
      enable_individual: false,
      enable_early_bird: false,
      display_schedule: false,
      available_online: false,
      require_membership: false,
      color: '#3f51b5',
    },
    season_options: null,
    properties: {
      Year: "",
      Season: "",
    }
  },
}

export const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    getAllLeagues: (state, { payload }) => {
      state.leagues = payload.leagues
        .map(l => ({
          ...l,
          start_date_parsed: unixToDate(l.start_date).format('M/D/YYYY'),
          end_date_parsed: unixToDate(l.end_date).format('M/D/YYYY'),
        }))
        .sort((a, b) => {
          if (a.start_date > b.start_date) return 1
          if (a.start_date < b.start_date) return -1
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })
    },
    getAllTournaments: (state, { payload }) => {
      state.tournaments = payload.tournaments
        .map(l => ({
          ...l,
          start_date_parsed: unixToDate(l.start_date).format('M/D/YYYY'),
          end_date_parsed: unixToDate(l.end_date).format('M/D/YYYY'),
        }))
        .sort((a, b) => {
          if (a.start_date > b.start_date) return 1
          if (a.start_date < b.start_date) return -1
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })
    },
    changeFilterDetails: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        [payload.key]: payload.value,
      }
    },
    getLeagueDetails: (state, { payload }) => {
      const start_date_parsed = unixToDate(payload.league.start_date),
        today = moment(),
        difference = start_date_parsed.diff(today, 'h'),
        start_status =
          difference > 0
            ? 'starts in ' + parseInt(difference / 24).toString() + ' days'
            : 'running'
      let invoiced = 0,
        paid = 0
      payload.league.teams.forEach(team => {
        if (team.invoice && team.invoice.ID) {
          invoiced += team.invoice.invoiced - team.invoice.discounted
          paid += team.invoice.paid
        }
      })
      state.league = {
        ...payload.league,
        start_date_parsed: start_date_parsed.format('M/D/YYYY'),
        end_date_parsed: moment(payload.league.end_date, 'X').format(
          'M/D/YYYY'
        ),
        start_status,
        invoiced,
        paid,
        balance: invoiced - paid,
      }
      state.create_league = {
        ...state.create_league,
        ...payload.league,
        start_date: payload.league.start_date,
        end_date: payload.league.end_date,
        registration_start_date: payload.league.registration_start_date,
        registration_end_date: payload.league.registration_end_date,
        early_bird_start_date: payload.league.early_bird_start_date,
        early_bird_end_date: payload.league.early_bird_end_date,
      }
    },
    editSearchQuery: (state, { payload }) => {
      state.query = payload.query
    },
    toggleModal: (state, { payload }) => {
      state.dialog_open = payload.open
      if ('edit' in payload) {
        state.dialog_edit = payload.edit
      }
      if ('redirect_to_league' in payload) {
        state.create_league = {
          ...state.create_league,
          redirect_to_league: payload.redirect_to_league,
        }
      }
      if ('tournament' in payload) {
        state.create_league = {
          ...state.create_league,
          tournament: true,
        }
      }
    },
    changeLeagueDetails: (state, { payload }) => {
      state.create_league = {
        ...state.create_league,
        [payload.field]: payload.value,
      }
    },
    getAgeGroups: (state, { payload }) => {
      state.age_groups = payload.age_groups
    },
    changeLeagueTab: (state, { payload }) => {
      state.league_tab = payload.tab
    },
    updateLeague: (state, { payload }) => {
      state.league = {
        ...state.league,
        [payload.key]: payload.value,
      }
    },
    setLeagueDetails: (state, { payload }) => {
      state.create_league = {
        ...state.create_league,
        ...payload.league,
        start_date: payload.league.start_date,
        end_date: payload.league.end_date,
        registration_start_date: payload.league.registration_start_date,
        registration_end_date: payload.league.registration_end_date,
        early_bird_start_date: payload.league.early_bird_start_date,
        early_bird_end_date: payload.league.early_bird_end_date,
      }
    },
    toggleAddTeamModal: (state, { payload }) => {
      state.add_team_dialog_open = payload.open
    },
    changeScheduleDetails: (state, { payload }) => {
      state.scheduler = {
        ...state.scheduler,
        [payload.field]: payload.value,
      }
    },
    addSchedulerSlot: (state, { payload }) => {
      state.scheduler_slots = state.scheduler_slots.concat(payload.slot_details)
    },
    removeSchedulerSlot: (state, { payload }) => {
      state.scheduler_slots = state.scheduler_slots.filter(
        s => s.token !== payload.token
      )
    },
    getScheduleMatches: (state, { payload }) => {
      const teams = payload.teams
      let min_week = 0
      state.scheduler_matches = payload.matches.map((m, i) => {
        const mom = moment(m.date, 'M/D/YYYY')
        if (i === 0) min_week = mom.week()
        return {
          ...m,
          moment_date: mom,
          date_parsed: mom.toDate(),
          time_parsed: moment(m.time, 'HH:mm').toDate(),
          token: uuidv4(),
          week: mom.week() - min_week + 1,
          home_name: teams.find(t => t.team.ID.toString() === m.home)?.team
            .name,
          away_name: teams.find(t => t.team.ID.toString() === m.away)?.team
            .name,
          to_check: !m.is_ok,
        }
      })
    },
    changeScheduledMatchDetails: (state, { payload }) => {
      state.scheduler_matches = state.scheduler_matches.map(m => {
        if (m.token === payload.token) {
          return {
            ...m,
            [payload.field]: payload.value,
            to_check: payload.to_check === undefined ? true : payload.to_check,
          }
        }
        return m
      })
    },
    getAllMatches: (state, { payload }) => {
      let mini_week = 0
      state.league_matches = payload.matches
        .sort((a, b) => {
          if (!a.event) {
            // e-match
            if (a.date > b.date) {
              return 1
            }
            return -1
          }
          if (a.event.start_date > b.event.start_date) {
            return 1
          }
          return -1
        })
        .concat(payload.playoffs || [])
        .map(m => {
          let start_date = m.event ? m.event.start_date : m.date
          const momen = moment(start_date, 'X')
          let e_league_date = null,
            e_league_time = null
          if (m.date) {
            e_league_date = unixToDate(m.date)
            e_league_time = unixToTime(m.date)
          }
          let week = momen.week()
          if (mini_week === 0) {
            mini_week = week - 1
          }
          week -= mini_week
          if (week <= 0) {
            week += 52
          }
          return {
            ...m,
            week,
            e_league_date,
            e_league_time,
          }
        })
    },
    getStandings: (state, { payload }) => {
      state.league_standings = payload.standings
    },
    changeMatchPage: (state, { payload }) => {
      state.league_page = payload.page
    },
    updateScore: (state, { payload }) => {
      state.league_matches = state.league_matches.map(m => {
        if (m.ID === payload.id) {
          return {
            ...m,
            [payload.side]: payload.score,
          }
        }
        return m
      })
      state.player_matches = state.player_matches.map(m => {
        if (m.ID === payload.id) {
          return {
            ...m,
            [payload.side]: payload.score,
          }
        }
        return m
      })
    },
    togglePlayerMatchModal: (state, { payload }) => {
      state.player_matches_dialog_open = payload.open
    },
    getPlayerMatches: (state, { payload }) => {
      state.player_matches = payload.matches.map(m => {
        let e_league_date = null,
          e_league_time = null
        if (m.date) {
          e_league_date = unixToDate(m.date)
          e_league_time = unixToTime(m.date)
        }
        return {
          ...m,
          e_league_date,
          e_league_time,
        }
      })
    },
    getPlayoffMatches: (state, { payload }) => {
      state.playoff_matches = payload.matches.map(m => ({
        ...m,
        start_parsed: unixToDateTime(m.event.start_date).format('MM/DD h:mmA'),
      }))
    },
    togglePlayerModal: (state, { payload }) => {
      state.player_profile_dialog_open = payload.open
    },
    getEPlayerDetails: (state, { payload }) => {
      state.eplayer = payload.eplayer
    },
    editEPlayerDetails: (state, { payload }) => {
      state.eplayer = {
        ...state.eplayer,
        [payload.field]: payload.value,
      }
    },
    updateEPlayerDetails: (state, { payload }) => {
      if (state.league) {
        state.league = {
          ...state.league,
          e_players: state.league.e_players.map(p => {
            if (p.player.ID === payload.eplayer.ID) {
              return {
                ...p,
                player: {
                  ...p.player,
                  ...payload.eplayer,
                },
              }
            }
            return p
          }),
        }
      }
    },
    removeEPlayer: (state, { payload }) => {
      state.league = {
        ...state.league,
        e_players: state.league.e_players.filter(
          p => p.player.ID !== payload.id
        ),
      }
    },
    toggleNewTeam: (state, { payload }) => {
      state.create_league = {
        ...state.create_league,
        create_team: payload.create_team,
      }
    },
    toggleAddGameModal: (state, { payload }) => {
      state.new_game = {
        ...state.new_game,
        modal_open: payload.open,
      }
    },
    updateGameDetails: (state, { payload }) => {
      state.new_game = {
        ...state.new_game,
        [payload.key]: payload.value,
      }
    },
    deleteMatch: (state, { payload }) => {
      state.league_matches = state.league_matches.filter(
        m => m.ID !== payload.id
      )
    },
    updateMatch: (state, { payload }) => {
      state.league_matches = state.league_matches.map(m => {
        if (m.ID === payload.id) {
          return {
            ...m,
            changed: true,
            event: {
              ...m.event,
              [payload.key]: payload.value,
            },
          }
        }
        return m
      })
    },
    changePlayoffDetails: (state, { payload }) => {
      if (payload.key === 'duration') {
        state.new_playoffs = {
          ...state.new_playoffs,
          duration: payload.value,
        }
        return
      }
      if (payload.key === 'round') {
        let round_settings = [],
          round_number = payload.value
        while (round_number >= 2) {
          round_settings.push({
            level: round_number,
            field_id: null,
            start_date: null,
            start_time: null,
            end_time: null,
          })
          round_number = round_number / 2
        }
        state.new_playoffs = {
          ...state.new_playoffs,
          round: payload.value,
          round_settings,
        }
        return
      }
      state.new_playoffs = {
        ...state.new_playoffs,
        round_settings: state.new_playoffs.round_settings.map((r, i) => {
          if (i === payload.i) {
            return { ...r, [payload.key]: payload.value }
          }
          return r
        }),
      }
    },
    buildPlayoffs: (state, { payload }) => {
      state.built_playoffs = payload.playoffs.map((match, i) => {
        const round_settings = state.new_playoffs.round_settings.find(
          r => r.level === match.level
        )
        return {
          ...match,
          field_id: round_settings.field.value,
          date_parsed: unixToDate(match.date).toDate(),
          time_parsed: unixToTime(match.date).toDate(),
          to_check: !match.is_ok,
        }
      })
    },
    editPlayoff: (state, { payload }) => {
      state.built_playoffs = state.built_playoffs.map((r, i) => {
        if (i === payload.i) {
          return { ...r, [payload.key]: payload.value, to_check: true }
        }
        return r
      })
    },
    checkNewPlayoffs: (state, { payload }) => {
      state.built_playoffs = state.built_playoffs.map((r, i) => {
        const is_ok = payload.checks[i].is_ok
        return {
          ...r,
          is_ok,
          to_check: !is_ok,
        }
      })
    },
    deleteLeague: (state, { payload }) => {
      const is_tournament = state.league.leagues?.find(
        l => l.ID === payload.id
      )?.is_tournament
      if (is_tournament) {
        state.league = {
          ...state.league,
          leagues: state.league.leagues.filter(l => l.ID !== payload.id),
        }
      } else {
        state.league = {
          ...state.league,
          divisions: state.league.divisions.filter(league => league.ID !== payload.id),
        }
      }
    },
    changeAddTeamTab: (state, { payload }) => {
      state.add_team_tab = payload.tab
    },
    updateBroadcastMessage: (state, { payload }) => {
      state.broadcast_message = {
        ...state.broadcast_message,
        [payload.key]: payload.value,
      }
    },
    changeTeamOrder: (state, { payload }) => {
      const new_index =
        state.league.teams.find(t => t.ID === payload.id).order + payload.delta
      state.league = {
        ...state.league,
        teams: state.league.teams.map(t => {
          if (t.ID === payload.id) {
            return { ...t, order: new_index }
          }
          if (t.order === new_index) {
            return { ...t, order: new_index - payload.delta }
          }
          return t
        }),
      }
    },
    updateNewDialog: (state, { payload }) => {
      state.new_division = {
        ...state.new_division,
        [payload.key]: payload.value,
      }
    },
    updateRolloverDialog: (state, { payload }) => {
      state.rollover = {
        ...state.rollover,
        [payload.key]: payload.value,
      }
    },
    updateRolloverDialogSettings: (state, { payload }) => {
      state.rollover.settings = {
        ...state.rollover.settings,
        [payload.key]: payload.value,
      }
    },
    updateRolloverDialogProperties: (state, { payload }) => {
      state.rollover.properties = {
        ...state.rollover.properties,
        [payload.key]: payload.value,
      }
    },
    updateRolloverDialogDates: (state, { payload }) => {
      state.rollover.dates = {
        ...state.rollover.dates,
        [payload.key]: payload.value,
      }
    },
    handleRolloverSelectLeague: (state, { payload }) => {
      if (state.rollover.selected_leagues.includes(payload.id)) {
        state.rollover.selected_leagues = state.rollover.selected_leagues.filter(id => id !== payload.id)
      } else {
        state.rollover.selected_leagues.push(payload.id)
      }
    },
    handleRolloverSelectAll: (state, { payload }) => {
      const leagues = state.league.divisions
      if (state.rollover.selected_leagues.length === leagues.length) {
        state.rollover.selected_leagues = []
      } else {
        state.rollover.selected_leagues = leagues.map(league => league.ID)
      }
    },
  },
})
