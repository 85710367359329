import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import TextField from 'common/components/FastTextField'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { CirclePicker } from 'react-color'

import { leagueSlice } from 'league/redux'
import { jsDateToUnix, unixToJsDate, getNextSeasonOptionsYear } from 'helper'
import { dispatchError, changeLoading } from 'common/actions'

class LeagueForm extends React.Component {
    componentDidMount() {
        if (this.props.old_settings?.ID) {
            this.props.changeLeagueDetails('league_parent_id', this.props.old_settings.ID)
        }
        if (this.props.old_settings?.properties && (!this.props.properties.Season || !this.props.properties.Year)) {
            let year = ""
            let season = ""
            this.props.old_settings.properties.forEach(property => {
                if (property.key == 'Year') {
                    year = property.value
                }
                if (property.key == 'Season') {
                    season = property.value
                }
            })
            const season_options = getNextSeasonOptionsYear(season, year)
            if (season_options.length === 0) {
                this.props.handleClose()
                this.props.dispatchError({message: `The previous season should be one of [Spring, Summer, Fall, Fall 2, Winter, Winter 1, Winter 2], instead got ${season.replace(' ', '_')}`})
                return
            }
            this.props.changeLeagueDetails('season_options', season_options.map(s => ({ label: s['season'] + ' ' + s['year'], value: s })))
            this.props.changeLeagueProperties('Year', season_options[0].year)
            this.props.changeLeagueProperties('Season', season_options[0].season)
            this.props.changeLeagueDetails('name', this.props.old_settings.name.replace(year, season_options[0].year).replace(season, season_options[0].season))
        }
    }
    
    render() {
        const setToOldSettings = () => {
            for (const k in this.props.old_settings) {
                if (k in this.props.settings) {
                    if (this.props.old_settings[k]) {
                        this.props.changeLeagueSettings(k, this.props.old_settings[k])
                        if (k === 'new_product_id') {
                            const new_product_price = this.props.newProducts.find(f => f.value === this.props.old_settings[k]).price
                            this.props.changeLeagueSettings('new_product_price', new_product_price)
                        }
                    }
                }
            }
        }

        const age_group_options = [{label:'Adult', value:1}, {label:'Youth', value:2}]

        return (
            <Grid
                container
                direction="column"
                style={{ textAlign: 'center', justifyContent:'center', alignItems:'center' }}
            >
                {this.props.current_tab === 'season' &&
                <Grid style={{ marginTop: 40, marginBottom: 40, width: '50%' }}>     
                    <Typography variant="h6" style={{ marginBottom:40 }}>
                        Select Season
                    </Typography>        
                    <Grid>
                        <Select
                            options={this.props.season_options}
                            placeholder="Select Season"
                            value={this.props.season_options?.find(f => f.value.season === this.props.properties.Season && f.value.year === this.props.properties.Year)}
                            onChange={e => {
                                this.props.changeLeagueDetails('name', this.props.name.replace(this.props.properties.Year, e.value.year).replace(this.props.properties.Season, e.value.season))
                                this.props.changeLeagueProperties("Season", e.value.season)
                                this.props.changeLeagueProperties("Year", e.value.year)
                            }}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                </Grid>
                }   


        {this.props.current_tab === 'settings' &&
        <>
        <Button
            onClick={() => setToOldSettings()}
            color="primary"
            style={{ marginBottom: 20, backgroundColor:'lightblue', borderWidth: 3, borderColor: 'black' }}
        >
            See previous settings
        </Button>

        <Grid
            container
            direction="row"
            alignItems="flex-start"
            style={{ textAlign: 'center', padding:20, backgroundColor: '#f5f5f5', justifyContent:'space-around', alignItems:'center' }}
            spacing={4}
        >

            <Grid item xs={8}>
                <Select
                    options={this.props.newProducts}
                    placeholder="Select Newproduct *"
                    value={
                    this.props.settings.new_product_id &&
                    this.props.newProducts.find(f => f.value === this.props.settings.new_product_id)
                    }
                    onChange={e => {
                        this.props.changeLeagueSettings('new_product_id', e.value)
                        this.props.changeLeagueSettings('new_product_price', e.price)
                    }}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                />
            </Grid>

            <Grid
                container
                direction="column"
                style={{ textAlign: 'center', width:'50%' }}
                spacing={4}
                
            >
                <Grid item xs={8}>
                    <Select
                        options={age_group_options}
                        placeholder="Select Age Group"
                        value={
                        this.props.settings.age_group_id &&
                        age_group_options.find(f => f.value === this.props.settings.age_group_id)
                        }
                        onChange={e => {
                            this.props.changeLeagueSettings('age_group_id', e.value)
                        }}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                    />
                </Grid>

                <Grid item xs={8}>
                    <TextField
                        label="Online description *"
                        key={this.props.settings.description}
                        fullWidth
                        multiline
                        rows="3"
                        variant="outlined"
                        value={this.props.settings.description}
                        onChange={e =>this.props.changeLeagueSettings('description', e.target.value)}
                        inputProps={{ style: { textAlign: 'center' } }}
                    />
                    {this.props.settings.description?.length > 0 && (
                    <Typography variant="h12" style={{ marginBottom:10, color:'red' }}>
                        Check for necessary changes in the description
                    </Typography>  
                    )}

                </Grid>

                <Grid
                    direction="row"
                    alignItems="center"
                    style={{ textAlign: 'center' }}
                    container
                    spacing={4}
                >

                    <Grid item xs={4}>
                        <TextField
                            label="Max number of teams *"
                            fullWidth
                            variant="outlined"
                            textAlign="center"
                            key={this.props.settings.max_teams}
                            value={this.props.settings.max_teams}
                            onChange={e => this.props.changeLeagueSettings('max_teams', e.target.value)}
                            type="number"
                            inputProps={{ style: { textAlign: 'center' } }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label="Match duration *"
                            fullWidth
                            textAlign="center"
                            variant="outlined"
                            key={this.props.settings.duration}
                            value={this.props.settings.duration}
                            onChange={e =>
                            this.props.changeLeagueSettings('duration', e.target.value)
                            }
                            type="number"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={8} style={{ textAlign:'left'}}>
                    Color{' '}
                    <CirclePicker
                        color={this.props.settings.color}
                        onChangeComplete={e =>this.props.changeLeagueSettings('color', e.hex)}
                    />
                </Grid>
            </Grid>

            <Grid
                direction="column"
                alignItems="flex-start"
                spacing={4}
            >
                {[
                    { label: 'Enable deposit', name: 'enable_deposit', checked: this.props.settings.enable_deposit },
                    { label: 'Enable individual registration', name: 'enable_individual', checked: this.props.settings.enable_individual },
                    { label: 'Display standings/schedules online', name: 'display_schedule', checked: this.props.settings.display_schedule },
                    { label: 'Available for online registration', name: 'available_online', checked: this.props.settings.available_online },
                    { label: 'Require membership', name: 'require_membership', checked: this.props.settings.require_membership },
                    { label: 'Enable early bird', name: 'enable_early_bird', checked: this.props.settings.enable_early_bird },
                ].map((item) => (
                    <Grid item xs={12} key={item.name}>
                        <FormControl fullWidth>
                            <FormGroup>
                                <FormControlLabel
                                control={
                                <Checkbox
                                    checked={item.checked}
                                    onChange={(e) => this.props.changeLeagueSettings(item.name, e.target.checked)}
                                    name={item.name}
                                />
                                }
                                label={
                                <Typography variant="body1" style={{ wordWrap: 'break-word' }}>
                                    {item.label}
                                </Typography>
                                }
                            />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    ))}
            </Grid>
        </Grid>
        </>
        }


        {this.props.current_tab === 'dates' &&
        <Grid
            container
            direction="row"
            alignItems="center"
            spacing={4}
            >

            <Grid item xs={6}>
                Start date
                <DatePickerComponent
                    id="league_start_date"
                    placeholder="Start date *"
                    value={unixToJsDate(this.props.dates.start_date)}
                    onChange={e => this.props.changeLeagueDates('start_date', jsDateToUnix(e.value))}
                />
            </Grid>

            <Grid item xs={6}>
                End date
                <DatePickerComponent
                    id="league_end_date"
                    placeholder="End date *"
                    value={unixToJsDate(this.props.dates.end_date)}
                    onChange={e => this.props.changeLeagueDates('end_date', jsDateToUnix(e.value))}
                />
            </Grid>

            <Grid item xs={6}>
                Registration start
                <DatePickerComponent
                    id="reg_start_date"
                    placeholder="Registration start date *"
                    value={unixToJsDate(this.props.dates.registration_start_date)}
                    onChange={e =>this.props.changeLeagueDates('registration_start_date',jsDateToUnix(e.value))}
                />
            </Grid>

            <Grid item xs={6}>
                Registration end
                <DatePickerComponent
                    id="reg_end_date"
                    placeholder="Registration end date *"
                    value={unixToJsDate(this.props.dates.registration_end_date)}
                    onChange={e =>this.props.changeLeagueDates('registration_end_date',jsDateToUnix(e.value))}
                />
            </Grid>

            <Grid item xs={6}>
                Early bird start
                <DatePickerComponent
                    id="eb_start_date"
                    placeholder="Early bird start date"
                    value={
                    this.props.settings.early_bird_start_date > 0
                        ? unixToJsDate(this.props.dates.early_bird_start_date)
                        : null
                    }
                    onChange={e =>
                    this.props.changeLeagueDates(
                        'early_bird_start_date',
                        jsDateToUnix(e.value)
                    )
                    }
                />
            </Grid>

            <Grid item xs={6}>
                Early bird end
                <DatePickerComponent
                    id="eb_end_date"
                    placeholder="Early bird end date"
                    value={
                    this.props.dates.early_bird_end_date > 0
                        ? unixToJsDate(this.props.dates.early_bird_end_date)
                        : null
                    }
                    onChange={e =>
                    this.props.changeLeagueDates(
                        'early_bird_end_date',
                        jsDateToUnix(e.value)
                    )
                    }
                />
            </Grid>
        </Grid>
        }

        {this.props.current_tab === 'name' &&
        <Grid
            container
            alignItems="center"
            spacing={4}
        >
            <Grid item xs={12}>
                <TextField
                    label="Name *"
                    key={this.props.name}
                    fullWidth
                    variant="outlined"
                    value={this.props.name}
                    onChange={e =>this.props.changeLeagueDetails('name', e.target.value)}
                    inputProps={{ style: { textAlign: 'center' } }}
                />
            </Grid>
        </Grid>
        }

        {this.props.current_tab === 'save' &&
        <Grid
            container
            alignItems="center"
            justifyContent='center'
            direction='column'
            spacing={4}
        >
            <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Confirm and Rollover League Season
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                it will take a few minutes to fully complete, you can use hydra in the meantime
            </Typography>
        </Grid>
        }
    </Grid>
    )}
}

const mapStateToProps = (state, ownProps) => {
    let newProducts = state.product.newProducts.filter(p => (p.team_price > 0))
    newProducts.forEach((product, index) => {
        let variation = product?.variations?.find(v => v.facility_id === state.league.create_league.facility_id) || null
        if (variation) {
            newProducts[index] = { ...variation, ID: product.ID };
        }
    })
    newProducts = newProducts.map(p => ({
        label: `${p.name} (full price = $${p.team_price} / deposit = $${p.deposit_price} / early bird = $${p.early_bird_price})`,
        value: p.ID,
        price: p.team_price,
        }))

    return {
    ...state.league.rollover,
    newProducts: newProducts,
    old_settings: state.league.create_league,
    selected_leagues: state.league.rollover.selected_leagues,
    facilities: state.common.filter_facilities.map(f => ({
        label: f.name,
        value: f.ID,
    })),
    is_admin: state.user.is_admin,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    changeLeagueDetails: (k, v) => dispatch(leagueSlice.actions.updateRolloverDialog({ key: k, value: v })),
    changeLeagueSettings: (k, v) => dispatch(leagueSlice.actions.updateRolloverDialogSettings({ key: k, value: v })),
    changeLeagueProperties: (k, v) => dispatch(leagueSlice.actions.updateRolloverDialogProperties({ key: k, value: v })),
    changeLeagueDates: (k, v) => dispatch(leagueSlice.actions.updateRolloverDialogDates({ key: k, value: v })),
    handleClose: () => dispatch(leagueSlice.actions.updateRolloverDialog({ key: 'open', value: false })),
    dispatchError: e => dispatch(dispatchError(e)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LeagueForm)
